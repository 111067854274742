/**
 * @generated SignedSource<<76a8a4f62897a114eb90b3e815e82c27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckArticleRenderTypeArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckCanShowInPlusPlatformArticle" | "helpersCheckIsPlusArticle" | "helpersCheckIsPostMagazineArticle" | "helpersCheckIsPostiesArticle" | "helpersCheckIsStyleArticle" | "helpersGetArticleTypeArticle">;
  readonly " $fragmentType": "helpersCheckArticleRenderTypeArticle";
};
export type helpersCheckArticleRenderTypeArticle$key = {
  readonly " $data"?: helpersCheckArticleRenderTypeArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckArticleRenderTypeArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckArticleRenderTypeArticle"
};

(node as any).hash = "04409356d3182f6ec132c56fa1c639e4";

export default node;
