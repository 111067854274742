import { notEmpty } from "@product/scmp-sdk";
import first from "lodash/first";
import { useMemo } from "react";
import { graphql, readInlineData } from "react-relay";

import { section as sectionData } from "shared/data/section";

import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import { useContentHelper } from "scmp-app/components/content/hooks";
import { titleCase } from "scmp-app/lib/utils";
import type { hooksContentHeadlineTagContent$key } from "scmp-app/queries/__generated__/hooksContentHeadlineTagContent.graphql";

type Type = "column" | "flag" | "opinion" | "recap" | "section" | "specialCase";

type HeadlineTagData = {
  color?: string;
  description?: string;
  entityId?: string;
  name?: string;
};

export const useDetermineHeadlineTag = (
  reference: hooksContentHeadlineTagContent$key,
  useSectionTags = false,
): HeadlineTagData | null => {
  const content = readInlineData(
    graphql`
      fragment hooksContentHeadlineTagContent on Content @inline {
        ... on Article {
          types {
            value {
              name
              entityId
              description {
                text
              }
            }
          }
          ...helpersCheckIsStyleArticle
        }
        flag
        sections {
          value {
            name
          }
        }
        ...hooksContentHelperContent
      }
    `,
    reference,
  );

  const {
    isContentWithFlag,
    isEditorialOpinionType,
    isRecapArticleType,
    isScmpOpinionType,
    isSpecialArticleType,
  } = useContentHelper(content);

  const headlineTagType = useMemo<null | Type>(() => {
    if (isScmpOpinionType) return "opinion";
    else if (isRecapArticleType) return "recap";
    else if (isEditorialOpinionType) return "column";
    else if (isContentWithFlag) return "flag";
    else if (isSpecialArticleType) return "specialCase";
    else if (useSectionTags) return "section";
    else return null;
  }, [
    isRecapArticleType,
    isContentWithFlag,
    isEditorialOpinionType,
    isScmpOpinionType,
    isSpecialArticleType,
    useSectionTags,
  ]);

  const isFlagLiveQuestionAndAnswer = useMemo(() => content.flag === "LIVE Q&A", [content.flag]);

  if (!notEmpty(headlineTagType)) return null;

  const [firstType, secondType] = (content.types ?? [])
    .flatMap(type => type?.value?.filter(item => notEmpty(item?.entityId)))
    .map(type => ({
      description: type?.description?.text ?? "",
      entityId: type?.entityId ?? "",
      name: type?.name ?? "",
    }));

  switch (headlineTagType) {
    case "column":
      return {
        color: "#34b8b5",
        description: secondType?.description ?? firstType?.description ?? "",
        entityId: secondType?.entityId ?? firstType?.entityId ?? "",
        name: secondType?.name ?? firstType?.name ?? "",
      };
    case "flag":
      return {
        color: isFlagLiveQuestionAndAnswer ? "#34b8b5" : "#c79a00",
        name: titleCase(content?.flag ?? ""),
      };
    case "opinion":
      return {
        color: checkIsStyleArticle(content) ? "#222222" : "#34b8b5",
        description: firstType?.description ?? "",
        entityId: firstType?.entityId ?? "",
        name: "Opinion",
      };
    case "recap":
      return {
        color: "#4585FF",
        name: "Recap",
      };
    case "section":
      return {
        color: "#666666",
        name: first(
          content.sections
            ?.flatMap(section => section?.value)
            ?.map(section => section?.name)
            ?.filter(notEmpty)
            ?.filter(
              section =>
                section !== sectionData.news.name && section !== sectionData.postMagazine.name,
            ) ?? [],
        ),
      };
    case "specialCase":
      return {
        color: "#c79a00",
        description: firstType?.description ?? "",
        entityId: firstType?.entityId ?? "",
        name: firstType?.name ?? "",
      };
    default:
      return null;
  }
};
