import { graphql, readInlineData } from "react-relay";

import { section as sectionData } from "shared/data";

import type { helpersCheckIsPostMagazineArticle$key } from "scmp-app/queries/__generated__/helpersCheckIsPostMagazineArticle.graphql";

export const checkIsPostMagazineArticle = (reference: helpersCheckIsPostMagazineArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckIsPostMagazineArticle on Article @inline {
        sections {
          value {
            entityId
          }
        }
      }
    `,
    reference,
  );

  const [firstSectionEntityId, secondSectionEntityId] =
    article?.sections?.flatMap(section => section?.value?.map(value => value?.entityId)) ?? [];

  const isNewPostMagazineArticle = firstSectionEntityId === sectionData.postMagazine.entityId;

  const isLegacyPostMagazineArticle =
    firstSectionEntityId === sectionData.magazine.entityId &&
    secondSectionEntityId === sectionData.postMagazine.entityId;

  return isNewPostMagazineArticle || isLegacyPostMagazineArticle;
};
