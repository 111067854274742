import { notEmpty } from "@product/scmp-sdk";
import first from "lodash/first";
import { graphql, readInlineData } from "react-relay";

import { article as articleData } from "shared/data";

import type { helpersCheckIsMainTopicContent$key } from "scmp-app/queries/__generated__/helpersCheckIsMainTopicContent.graphql";
import type { helpersGetArticleTypeNameArticle$key } from "scmp-app/queries/__generated__/helpersGetArticleTypeNameArticle.graphql";
import type { helpersGetContentTypeNameContent$key } from "scmp-app/queries/__generated__/helpersGetContentTypeNameContent.graphql";
import type { helpersSanitizeArticleTypeEntityIdsArticle$key } from "scmp-app/queries/__generated__/helpersSanitizeArticleTypeEntityIdsArticle.graphql";
import type { helpersSanitizeAuthorTypesArticle$key } from "scmp-app/queries/__generated__/helpersSanitizeAuthorTypesArticle.graphql";
import type { helpersSanitizedAuthorEntityIdsArticle$key } from "scmp-app/queries/__generated__/helpersSanitizedAuthorEntityIdsArticle.graphql";

export const checkIsMainTopic = (
  reference_: helpersCheckIsMainTopicContent$key,
  entityId?: string,
) => {
  if (!entityId) return false;

  const content = readInlineData(
    graphql`
      fragment helpersCheckIsMainTopicContent on Content @inline {
        topics {
          entityId
        }
      }
    `,
    reference_,
  );

  // TODO: missing 20PartyCongress and HK25 logic here
  return first(content.topics)?.entityId === entityId;
};

export const sanitizeArticleTypeEntityIds = (
  reference: helpersSanitizeArticleTypeEntityIdsArticle$key,
) => {
  const article = readInlineData(
    graphql`
      fragment helpersSanitizeArticleTypeEntityIdsArticle on Article @inline {
        types {
          value {
            entityId
          }
        }
      }
    `,
    reference,
  );
  return (
    article?.types
      ?.flatMap(type => type?.value)
      ?.map(type => type?.entityId ?? "")
      ?.filter(notEmpty) ?? []
  );
};

export const sanitizeAuthorTypes = (reference: helpersSanitizeAuthorTypesArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersSanitizeAuthorTypesArticle on Article @inline {
        authors {
          types
        }
      }
    `,
    reference,
  );
  return article?.authors?.flatMap(author => author?.types?.filter(notEmpty) ?? []) ?? [];
};

export const sanitizedAuthorEntityIds = (reference: helpersSanitizedAuthorEntityIdsArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersSanitizedAuthorEntityIdsArticle on Article @inline {
        authors {
          entityId
        }
      }
    `,
    reference,
  );
  return article?.authors?.flatMap(author => author?.entityId ?? "")?.filter(notEmpty) ?? [];
};

export const getContentTypeName = (reference: helpersGetContentTypeNameContent$key) => {
  const content = readInlineData(
    graphql`
      fragment helpersGetContentTypeNameContent on Content @inline {
        __typename
        ... on Article {
          ...helpersGetArticleTypeNameArticle
        }
      }
    `,
    reference,
  );

  const typeName = content.__typename as "Article" | "Gallery" | "Video";

  switch (typeName) {
    case "Gallery":
      return "gallery";
    case "Video":
      return "video";
    default:
      if (content.__typename === "Article") {
        return getArticleTypeName(content);
      }
      return "article";
  }
};

const getArticleTypeName = (reference: helpersGetArticleTypeNameArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersGetArticleTypeNameArticle on Article @inline {
        types {
          value {
            entityId
          }
        }
      }
    `,
    reference,
  );

  const [articleType] = article.types?.flatMap(type => type?.value) ?? [];

  switch (articleType?.entityId) {
    case articleData.types.infographic.entityId:
      return "infographic";
    case articleData.types.longRead.entityId:
      return "long read";
    case articleData.types.opinion.entityId:
      return "opinion";
    case articleData.types.podcast.entityId:
      return "podcast";
    default:
      return "article";
  }
};
