import { notEmpty } from "@product/scmp-sdk";
import { graphql, readInlineData } from "react-relay";

import { section } from "shared/data";

import type { helpersCheckIsPostiesArticle$key } from "scmp-app/queries/__generated__/helpersCheckIsPostiesArticle.graphql";

export const checkIsPostiesArticle = (reference: helpersCheckIsPostiesArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckIsPostiesArticle on Article @inline {
        sections {
          value {
            entityId
          }
        }
      }
    `,
    reference,
  );
  const sections = article?.sections?.flatMap(section =>
    section?.value?.map(value => value && value.entityId),
  );
  return notEmpty(sections?.includes(section.posties.entityId));
};
